import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import DentalServiceBox from "@components/box-large-image/layout-02";
import { SectionTitleType, ItemType } from "@utils/types";
import { DentalServiceWrapper } from "./style";

const DentalServiceArea = ({ data }) => {
    return (
        <DentalServiceWrapper>
            <Container>
                {data?.section_title && (
                    <SectionTitle
                        mb={["46px", null, "60px"]}
                        title={data.section_title?.title}
                        subtitle={data.section_title?.subtitle}
                    />
                )}

                {data?.items && (
                    <Row>
                        {data?.items?.map((dentalService) => (
                            <Col lg={4} md={6} mb="30px" key={dentalService.id}>
                                <DentalServiceBox
                                    image={dentalService.featured_image}
                                    title={dentalService.title}
                                    category={dentalService.category}
                                    desc={dentalService.excerpt}
                                    path={`/dental-service/${dentalService.slug}`}
                                    btnText="View service"
                                    layout={2}
                                />
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
        </DentalServiceWrapper>
    );
};

DentalServiceArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default DentalServiceArea;
