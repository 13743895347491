const translations = {
    en: {
        Search: "Search",
        ExploreAlbania: "Explore the Hidden Gem: Why Visit Albania?",
        GetStarted: "Get Started",
        Services: "Services",
    },
    it: {
        Search: "Ricerca",
        ExploreAlbania: "Esplora il gioiello nascosto: Perché visitare l'Albania?",
        GetStarted: "Loslegen",
        Services: "Servizi"
    },
    de: {
        Search: "Suche",
        ExploreAlbania: "Entdecke das verborgene Juwel: Warum Albanien besuchen?",
        GetStarted: "Loslegen",
        Services: "Dienstleistungen",
    },
    sq: {
        Search: "Kërko",
        ExploreAlbania: "Eksploro Gurin e Cmuar te Fshehtë: Pse të Vizitosh Shqipërinë?",
        GetStarted: "Fillo",
        Services: "Shërbimet",
    },
};

export default translations;
